import React, { createContext, useEffect, useState } from 'react';
import Client, { LineItem } from 'shopify-buy';

import isBrowser from '../utils/isBrowser';

declare global {
  interface Window {
    Sentry: any;
  }
}

const client = Client.buildClient({
  domain: `loftlifestyle.myshopify.com`,
  storefrontAccessToken: `115e3f243dd0db82191028acfc403e87`,
}) as ShopifyBuy.Client;

export const defaultValues = {
  isCartOpen: false,
  setIsCartOpen: (toggle: boolean) => {},
  // cart: [],
  addProductToCart: (lineItems: LineItem[]) => {},
  removeProductFromCart: (string: string) => {},
  removeAllProductsFromCart: ([]) => {},
  client: client,
  checkout: {
    checkoutUrl: ``,
    id: ``,
    lineItemCount: 0,
    lineItems: [],
    subtotalPrice: ``,
    totalPrice: { amount: `` },
    webUrl: ``,
    completedAt: null,
  },
};

export const StoreContext = createContext(defaultValues);

interface StoreProviderProps {
  children: React.ReactNode;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const [checkout, setCheckout] = useState<Client.Cart>(defaultValues.checkout);

  let isCartOpenDefault = false;
  if (isBrowser()) {
    const rememberedCartIsOpen = localStorage.getItem(`cart_open`);
    isCartOpenDefault = rememberedCartIsOpen === `true` ? true : false;
  }
  const [isCartOpen, setCartOpen] = useState(isCartOpenDefault);

  const setIsCartOpen = (state: boolean) => {
    setCartOpen(state);
    localStorage.setItem(`cart_open`, `${state}`);
  };

  const initializeCheckout = async () => {
    const getNewId = async () => {
      try {
        const newCheckout = await client.checkout.create();
        if (isBrowser()) {
          localStorage.setItem(`checkout_id`, newCheckout.id as string);
        }
        return newCheckout;
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    try {
      // If we have a currentCheckoutId in local storage, grab it
      const currentCheckoutId = isBrowser()
        ? localStorage.getItem(`checkout_id`)
        : null;

      let newCheckout = null;
      if (currentCheckoutId) {
        // If we have a currentCheckoutId, get the shopify checkout with it
        try {
          newCheckout = await client.checkout.fetch(currentCheckoutId);

          if (newCheckout) {
            // @ts-ignore
            if (newCheckout.completedAt) {
              newCheckout = await getNewId();
            }
          } else {
            newCheckout = await getNewId();
          }
        } catch (error) {
          window.Sentry.captureMessage(
            `Could not fetch client.checkout so now making a new one`,
            `info`,
          );
          newCheckout = await getNewId();
        }
      } else {
        // There is no current checkout, so lets make one and save it for next time
        newCheckout = await getNewId();
      }
      // @ts-ignore
      setCheckout(newCheckout);
      // Set the checkout into our global state - yummy
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => {
    initializeCheckout();
  }, []);

  const addProductToCart = async (lineItems: LineItem[]) => {
    try {
      const newCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems,
      );
      setCheckout(newCheckout);
      setIsCartOpen(true);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const removeProductFromCart = async (lineItemId: string) => {
    try {
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [
        lineItemId,
      ]);
      setCheckout(newCheckout);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const removeAllProductsFromCart = async (lineItemIds: string[]) => {
    try {
      if (checkout.id && lineItemIds.length > 0) {
        const newCheckout = await client.checkout.removeLineItems(
          checkout.id,
          lineItemIds,
        );
        setCheckout(newCheckout);
      }
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addProductToCart,
        removeProductFromCart,
        removeAllProductsFromCart,
        // @ts-ignore
        checkout,
        isCartOpen,
        setIsCartOpen,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
