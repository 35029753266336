import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import { StoreProvider } from '../context/StoreContext';
import theme from '../utils/theme';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    min-width: 320px;

    font-size: 18px;
    letter-spacing: 0.08em;

    @media (max-width: 1440px) {
      font-size: 16px;
    }
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Cinzel', serif;
    font-weight: 400;
    letter-spacing: 0.06em;
  }

  #gatsby-focus-wrapper {
    position: relative;
  }
`;

interface WrapRootElementProps {
  element: React.ReactNode;
}

export const wrapRootElement = ({ element }: WrapRootElementProps) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <StoreProvider>{element}</StoreProvider>
      </>
    </ThemeProvider>
  );
};
