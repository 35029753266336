exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-returns-tsx": () => import("./../../../src/pages/returns.tsx" /* webpackChunkName: "component---src-pages-returns-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-shipping-tsx": () => import("./../../../src/pages/shipping.tsx" /* webpackChunkName: "component---src-pages-shipping-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-visit-tsx": () => import("./../../../src/pages/visit.tsx" /* webpackChunkName: "component---src-pages-visit-tsx" */),
  "component---src-templates-product-page-template-tsx": () => import("./../../../src/templates/ProductPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-page-template-tsx" */),
  "component---src-templates-shop-page-template-tsx": () => import("./../../../src/templates/ShopPageTemplate.tsx" /* webpackChunkName: "component---src-templates-shop-page-template-tsx" */)
}

