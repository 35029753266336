import { DefaultTheme } from 'styled-components';

// eslint-disable-next-line quotes
declare module 'styled-components' {
  export interface DefaultTheme {
    color: {
      blue: string;
      green: string;
      grey: string;
      darkGrey: string;
      loft: string;
      red: string;
      white: string;
      pink: string;
    };
    shadow: {
      box: string;
    };
  }
}

const theme: DefaultTheme = {
  color: {
    blue: `#222B56`,
    green: `#4C8F25`,
    grey: `#4B494A`,
    darkGrey: `#4B494A`,
    loft: `#989A9B`,
    red: `#D0103A`,
    white: `#FFFFFF`,
    pink: `#af003b`,
  },
  shadow: {
    box: `0 4px 20px rgba(0, 0, 0, 0.2)`,
  },
};

export default theme;
